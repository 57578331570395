<template>
  <div>

    <v-card width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="authErrors"/>
    <v-card-title>
      Neuen Lehrer-Account anlegen
    </v-card-title>
    <v-card-text>
      Hier kannst du einen Account für einen neuen Lehrer in deinem Institut anlegen.
      Der Lehrer bekommt dann automatisch eine E-Mail in der er sein Passwort zurücksetzen kann. <br>
      Bitte informiere ihn vorher, dass er eine Mail von ALearn erhalten wird, damit er nicht verwirrt ist.
      <v-form @submit.prevent v-model="valid" class="mt-5">
        <v-text-field 
        type="text" 
        label="Vorname"
        autocomplete="firstname"
        v-model.trim="signupForm.firstname"
        :rules="nameRules"
        required
        />

        <v-text-field 
        type="text" 
        label="Nachname" 
        autocomplete="lastname"
        v-model.trim="signupForm.lastname"
        :rules="nameRules"
        required
        />
      
        <v-text-field 
        type="email" 
        label="E-Mail"
        autocomplete="email"
        v-model.trim="signupForm.email"
        :rules="emailRules"
        required
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="mx-2">
      <v-spacer></v-spacer>
      <v-btn 
      color="primary"
      block
      depressed 
      :disabled="!valid"
      @click="signup()"
      >
        <div v-if="!loadSignUp">
          Neuen Lehrer registrieren
        </div>
        
        <v-progress-circular
          v-if="loadSignUp"
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import {functions, auth} from '@/firebase';
import Errors from '@/components/Errors.vue';

export default {
  data() {
    return {
      signupForm: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      },
      valid: false,
      nameRules: [
        v => !!v || 'Gib einen Namen an.',
        v => v.length <= 20 || 'Der Name muss kürzer als 20 Zeichen sein.',
      ],
      email: '',
      emailRules: [
        v => !!v || 'Gib eine E-mail-Adresse an.',
        v => /.+@.+/.test(v) || 'Gib eine gültige E-Mail-Adresse ein.',
      ],
      roleErrors: [{
        text: 'Du kannst keine neuen Lehrer-Accounts anlegen.', 
        advice:'Bitte einen Administrator deines Instituts einen neuen Lehrer-Account anzulegen.',
      }],
      authErrors: [],
      loadSignUp: false,
    };
  },

  components: {
    Errors,
  },

  created() {

  },

  methods: {
    
    async signup() {
        if (this.valid) {
          this.loadSignUp = true;
          console.log('signing up...');
    
          //Get the institueId of the currently logged in user
          let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
          //let instituteId = 'qta0xwngYmIaQKf9OPdn'; //dummydata for testinstitut
          if(typeof instituteId !== 'undefined'){
            //add a new user using cloud functions
            const addUser = functions.httpsCallable('addUser');
             addUser({
              instituteId: instituteId, 
              email: this.signupForm.email, 
              firstname: this.signupForm.firstname, 
              lastname: this.signupForm.lastname
            }).then(result => {
              this.loadSignUp = false;
              this.authErrors = [];
              console.log(result);
              /* auth.sendPasswordResetEmail(this.signupForm.email); */
            }).catch(error => {
              this.loadSignUp = false;
              this.authErrors.push(
                {
                  text: error.message,
                }
              )
            });
          } else {
            this.loadSignUp = false;
            throw 'This user does not have an instituteId. You cannot add a new user without an instituteId.';
          }
    
        }
    }
  }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>